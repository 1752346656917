import { useMemo } from 'react';

import { useQueryStatsMTBA } from '../../../../adapters';
import { statsFormatCount, statsFormatHours } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowMTBA = MobileTableRow<{
  uptimeHours: number;
  aogCount: number;
}>;

export const StatsMTBATableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTBA);

  const rows: MobileTableRowMTBA[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowMTBA[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowMTBA[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        downtimeValue,
        totalValue,
        aogCount,
        intervals,
        mtba,
        mtbaPrev,
      }) => {
        const intervalValues = intervalFields.map(date => {
          const uptimeHours =
            intervals[date].totalValue - intervals[date].downtimeValue;
          const aogCount = intervals[date].aogCount;
          return {
            date,
            uptimeHours,
            aogCount,
          };
        });

        return {
          tailNumber,
          uptimeHours: totalValue - downtimeValue,
          aogCount,
          rate: [mtba, mtbaPrev],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'uptimeHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      rateField={{
        valueFormatter: statsFormatHours,
      }}
      summaryRows={[
        {
          field: 'uptimeHours',
          label: 'Total Uptime Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'aogCount',
          label: 'Total AOG Events',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'MTBA',
          valueFormatter: statsFormatHours,
        },
      ]}
      intervalColumns={['', 'Uptime h / AOGs']}
      rateStrategy="asc"
    />
  );
};
