/* eslint-disable sonarjs/no-duplicate-string */
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  styled,
  accordionClasses,
  accordionSummaryClasses,
  accordionDetailsClasses,
  listItemClasses,
  svgIconClasses,
} from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { ratedColor } from '../stat-constants';

import { statsTrendedValueClasses, TrendedValue } from './TrendedValue';

const PREFIX = 'TSMED-StatsTableMobile';

export const statsTableMobileClasses = {
  rowCommon: `${PREFIX}-rowCommon`,
  rowTrended: `${PREFIX}-rowTrended`,
  headerPanel: `${PREFIX}-headerPanel`,
  value: `${PREFIX}-value`,
  valueSecondary: `${PREFIX}-valueSecondary`,
  negative: `${PREFIX}-negative`,
  rowSummaryStats: `${PREFIX}-rowSummaryStats`,
  rowIntervalStats: `${PREFIX}-rowIntervalStats`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  [`.${accordionClasses.root}`]: {
    boxShadow: 'none',
    borderWidth: '0 0 1px 0',
    borderColor: palette.mapBlue[700],
    background: `${palette.mapBlue[700]}80`,
    borderRadius: 0,
    [`&:first-of-type`]: {
      borderTopLeftRadius: spacing(1),
      borderTopRightRadius: spacing(1),
    },
    ['&.Mui-expanded']: {
      margin: 0,
    },
  },
  [`.${accordionSummaryClasses.root}`]: {
    background: 'none',
    borderColor: palette.mapBlue[700],
    gap: spacing(2),
    ['&.Mui-expanded']: {
      minHeight: 'fit-content',
    },
  },
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    [`.${svgIconClasses.root}`]: {
      color: 'white',
      width: 24,
      height: 24,
    },
  },
  [`.${accordionSummaryClasses.content}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '27px',
    margin: 0,
    ['&.Mui-expanded']: {
      minHeight: 'fit-content',
      margin: 0,
    },
  },
  [`.${accordionDetailsClasses.root}`]: {
    padding: spacing(1, 1, 2),
    borderBlock: `1px solid ${palette.mapBlue[700]}`,
    background: palette.mapBlue[800],
    gap: spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    color: palette.grey[400],
  },
  [`.${statsTableMobileClasses.value}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '16px',
    alignItems: 'center',
  },
  [`.${statsTableMobileClasses.valueSecondary}`]: {
    // color: '#87888B',
    // fontSize: '12px',
  },
  [`.${statsTableMobileClasses.negative}`]: {
    color: 'red',
  },
  [`.${statsTableMobileClasses.rowCommon}`]: {
    padding: spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '27px',
  },
  [`.${statsTrendedValueClasses.root}`]: {
    fontSize: '16px',
    padding: spacing(0, 1),
    gap: 2,
    borderRadius: spacing(0.5),
    [`.${statsTrendedValueClasses.icon}`]: {
      width: 16,
      height: 16,
    },
    [`&.${statsTrendedValueClasses.negative}`]: {
      color: palette.error.dark,
      [`.${statsTrendedValueClasses.icon}`]: {
        color: palette.error.dark,
      },
    },
    [`&.${statsTrendedValueClasses.positive}`]: {
      color: palette.success.dark,
      [`.${statsTrendedValueClasses.icon}`]: {
        color: palette.success.dark,
      },
    },
  },

  [`.${statsTableMobileClasses.rowSummaryStats}`]: {
    [`.${listItemClasses.root}`]: {
      padding: spacing(1.5, 2),
      [`.${statsTrendedValueClasses.root}`]: {
        background: 'none',
      },
    },
  },
  [`.${statsTableMobileClasses.rowIntervalStats}`]: {
    fontSize: '16px',
    background: palette.mapBlue[900],
    borderRadius: spacing(1),
    border: `1px solid ${palette.mapBlue[700]}`,
    color: palette.grey[700],
    [`.${listItemClasses.root}`]: {
      borderTop: `1px solid ${palette.mapBlue[700]}`,
      [`& + .${listItemClasses.root}`]: {
        borderTop: `1px solid ${palette.mapBlue[700]}`,
      },
      padding: spacing(1.5, 2),
    },
  },
}));

export type StatsFieldSimpleValue = number | string;
export type StatsFieldRateValue = object | [number, number];
export type StatsFieldValue = StatsFieldSimpleValue | StatsFieldRateValue;
export type StatsFieldMap = Record<string, StatsFieldValue>;
export type MobileTableRow<
  F extends StatsFieldMap,
  I extends StatsFieldMap = F,
> = {
  [P in keyof F]: F[P];
} & {
  tailNumber: string;
  rate: [number, number];
  intervals: MobileTableRowInterval<I>[];
};

type MobileTableRowInterval<I> = {
  [P in keyof I]: I[P];
} & {
  date: string;
};

export interface StatsTableMobileProps<
  F extends StatsFieldMap,
  I extends StatsFieldMap = F,
> {
  rows: MobileTableRow<F, I>[];
  rateField?: {
    valueFormatter: (value: number) => string;
  };
  intervalField: {
    fieldName: keyof I;
    valueFormatter: (value: number) => string;
  };
  intervalSecondaryField?: {
    fieldName: keyof I;
    valueFormatter: (value: number) => string;
  };
  summaryRows: {
    field: keyof MobileTableRow<F, I>;
    label: string;
    valueFormatter: (value: number) => string;
  }[];
  // ratedColor: (row: MobileTableRow) => string;
  intervalColumns: [string, string];
  rateStrategy?: 'asc' | 'desc';
}
export const StatsTableMobile = <
  F extends StatsFieldMap,
  I extends StatsFieldMap,
>({
  rateField,
  intervalColumns,
  intervalField: primaryField,
  intervalSecondaryField: secondaryField,
  rows,
  summaryRows,
  rateStrategy = 'asc',
}: StatsTableMobileProps<F, I>) => {
  const sortedRows = useMemo(() => {
    const compareValues = (
      a: MobileTableRow<F, I>,
      b: MobileTableRow<F, I>,
    ) => {
      if (rateStrategy === 'asc') {
        return a.rate[0] > b.rate[0] ? 1 : -1;
      }
      return a.rate[0] < b.rate[0] ? 1 : -1;
    };
    return [...rows].sort(compareValues);
  }, [rateStrategy, rows]);
  const maxRate = useMemo(
    () => Math.max(...sortedRows.map(row => row.rate[0])),
    [sortedRows],
  );
  const minRate = useMemo(
    () => Math.min(...sortedRows.map(row => row.rate[0])),
    [sortedRows],
  );
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Root>
      {sortedRows.map(row => {
        const [rate, prevRate] = row.rate;
        const ratePerCent = (rate - minRate) / (maxRate - minRate);
        const rateBgColor =
          (Number.isNaN(ratePerCent) && 'inherit') ||
          `${ratedColor(
            rateStrategy === 'asc' ? 1 - ratePerCent : ratePerCent,
          )}20`;
        const rateColor =
          (Number.isNaN(ratePerCent) && 'inherit') ||
          `${ratedColor(
            rateStrategy === 'asc' ? 1 - ratePerCent : ratePerCent,
          )}`;
        let kind: 'positive' | 'negative' | undefined;
        if (rate !== prevRate) {
          if (
            (rateStrategy === 'asc' && rate > prevRate) ||
            (rateStrategy === 'desc' && rate < prevRate)
          ) {
            kind = 'positive';
          } else {
            kind = 'negative';
          }
        }
        return (
          <Accordion
            key={row.tailNumber}
            expanded={expanded === row.tailNumber}
            onChange={handleChange(row.tailNumber)}
          >
            <AccordionSummary
              className={statsTableMobileClasses.rowCommon}
              expandIcon={<ExpandMoreIcon />}
            >
              <Box>{row.tailNumber}</Box>
              <TrendedValue
                sx={{
                  background: rateBgColor,
                }}
                primaryValue={
                  rateField?.valueFormatter
                    ? rateField.valueFormatter(rate)
                    : rate
                }
                kind={kind}
                icon={
                  (rate === prevRate && 'balance') ||
                  (rate < prevRate && 'down') ||
                  'up'
                }
                color={rateColor}
              />
            </AccordionSummary>
            <AccordionDetails>
              <List className={statsTableMobileClasses.rowSummaryStats}>
                {summaryRows.map(
                  ({ field: fieldName, label, valueFormatter }) => {
                    const field = row[fieldName] as number | Array<number>;

                    if (Array.isArray(field)) {
                      const [rateValue, prevRateValue] = field as [
                        number,
                        number,
                      ];
                      return (
                        <ListItem
                          key={label}
                          className={statsTableMobileClasses.rowCommon}
                          sx={{
                            background: rateBgColor,
                          }}
                        >
                          <Box>{label}</Box>
                          <TrendedValue
                            primaryValue={
                              valueFormatter
                                ? valueFormatter(rateValue)
                                : rateValue
                            }
                            kind={
                              rateValue > prevRateValue &&
                              rateStrategy === 'asc'
                                ? 'positive'
                                : 'negative'
                            }
                            icon={
                              rateValue === prevRateValue
                                ? undefined
                                : (rateValue < prevRateValue && 'down') || 'up'
                            }
                            color={rateColor}
                          />
                        </ListItem>
                      );
                    }
                    const fieldValue = field ?? 0;
                    return (
                      <ListItem
                        key={label}
                        className={statsTableMobileClasses.rowCommon}
                      >
                        <Box>{label}</Box>
                        <Box>
                          {valueFormatter
                            ? valueFormatter(fieldValue)
                            : fieldValue}
                        </Box>
                      </ListItem>
                    );
                  },
                )}
              </List>
              <List className={statsTableMobileClasses.rowIntervalStats}>
                <ListItem className={statsTableMobileClasses.rowCommon}>
                  <Box>{intervalColumns[0]}</Box>
                  <Box className={statsTableMobileClasses.value}>
                    {intervalColumns[1]}
                  </Box>
                </ListItem>
                {row.intervals.map(interval => {
                  const primaryValue = interval[
                    primaryField.fieldName
                  ] as number;
                  const secondaryValue =
                    secondaryField &&
                    (interval[secondaryField?.fieldName] as number);
                  return (
                    <ListItem
                      className={statsTableMobileClasses.rowCommon}
                      key={interval.date}
                    >
                      <Box>{dayjs(interval.date).format('MM/DD/YYYY')}</Box>
                      <Box className={statsTableMobileClasses.value}>
                        {primaryField.valueFormatter
                          ? primaryField.valueFormatter(primaryValue)
                          : primaryValue}
                        {secondaryValue !== undefined && (
                          <Box
                            className={classNames(
                              statsTableMobileClasses.valueSecondary,
                            )}
                          >
                            /{' '}
                            {secondaryField?.valueFormatter
                              ? secondaryField.valueFormatter(secondaryValue)
                              : secondaryValue}
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Root>
  );
};
