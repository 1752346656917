import { styled, useMediaQuery, useTheme } from '@mui/material';
import {
  axisClasses,
  BarPlot,
  ChartsGrid,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer,
  ChartsLegend,
  LinePlot,
  MarkPlot,
  AxisConfig,
} from '@mui/x-charts';
import { ChartsXAxisProps } from '@mui/x-charts/internals';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useQueryStatsMTBA } from '../../../../adapters';
import { useQueryData } from '../useQueryData';

const PREFIX = 'TSMED-StatsMTBAChart';

const Root = styled(ResponsiveChartContainer, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints } }) => ({
  padding: spacing(2),
  background: '#0F151C',
  [`.${axisClasses.directionX}`]: {
    [`.${axisClasses.tickLabel}`]: {
      translate: spacing(0, 1),
      [breakpoints.down('sm')]: {
        rotate: '-45deg',
        translate: spacing(-2, 7),
      },
    },
  },
}));

export const StatsMTBAChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { palette } = useTheme();
  const { data: statsData } = useQueryData(useQueryStatsMTBA);

  const data = useMemo(() => {
    if (!statsData) {
      return [];
    }

    return Object.entries(statsData.perDate)
      .sort((a, b) => (a[0] < b[0] ? -1 : 1))
      .map(([date, { mtba }]) => ({
        date: dayjs(date).format('MM/DD/YYYY'),
        mtba,
      }));
  }, [statsData]);

  return (
    <Root
      margin={{
        bottom: isMobile ? 176 : 96,
      }}
      height={430}
      dataset={data}
      series={[
        {
          dataKey: 'mtba',
          label: 'MTBA',
          color: palette.mapBlue[600],
          type: 'bar',
        },
        {
          dataKey: 'mtba',
          color: palette.success.main,
          type: 'line',
        },
      ]}
      xAxis={[
        {
          scaleType: 'band',
          dataKey: 'date',
          barGapRatio: 0.5,
          categoryGapRatio: 0.8,
        } as AxisConfig<'band', unknown, ChartsXAxisProps>,
      ]}
    >
      <ChartsLegend
        labelStyle={{
          fontSize: 14,
          fill: palette.grey[400],
        }}
        direction={'row'}
        position={{ vertical: 'bottom', horizontal: 'left' }}
        itemMarkHeight={8}
        itemMarkWidth={8}
        itemGap={16}
        markGap={8}
      />
      <ChartsGrid vertical horizontal />
      <ChartsXAxis fill={palette.mapBlue[700]} />
      <ChartsYAxis />
      <BarPlot />

      <LinePlot />
      <MarkPlot />
    </Root>
  );
};
