import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsAOGDR } from '../../../../adapters';
import { Loader } from '../../../../components';
import { statsFormatCount, statsFormatPercent } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsAOGDRChart } from './StatsAOGDRChart';
import { StatsAOGDRTable } from './StatsAOGDRTable';
import { StatsAOGDRTableMobile } from './StatsAOGDRTableMobile';

const PREFIX = 'TSMED-StatsAOGDRTab';

const statsAOGDRClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsAOGDRClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsAOGDRClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsAOGDRClasses.fieldNegative}`]: {},
  [`.${statsAOGDRClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsAOGDR = () => {
  const { data: statsData } = useQueryData(useQueryStatsAOGDR);

  const summaryItems: ISummaryItem[] | undefined = useMemo(() => {
    if (!statsData) {
      return;
    }

    const totalScheduledCount = statsData.summary.scheduledCount;
    const totalScheduledPrevCount = statsData.summary.scheduledPrevCount;
    const totalCancelledCount = statsData.summary.cancelledCount;
    const totalCancelledPrevCount = statsData.summary.cancelledPrevCount;
    const aogdr = statsData.summary.aogdr;
    const aogdrPrev = statsData.summary.aogdrPrev;

    return [
      {
        id: 'total-scheduled-flights',
        label: 'Total Scheduled Flights',
        primaryValue: statsFormatCount(totalScheduledCount),
        secondaryValue: ` / ${statsFormatCount(totalScheduledPrevCount)}`,
      },
      {
        id: 'total-delayed-flights',
        label: 'Total Cancelled Flights',
        primaryValue: statsFormatCount(totalCancelledCount),
        secondaryValue: ` / ${statsFormatCount(totalCancelledPrevCount)}`,
      },
      {
        id: 'aogdr',
        label: 'AOG Dispatch Reliability',
        primaryValue: statsFormatPercent(aogdr),
        secondaryValue: ` / ${statsFormatPercent(aogdrPrev)}`,
        icon:
          (aogdr === aogdrPrev && 'balance') ||
          (aogdr < aogdrPrev && 'down') ||
          'up',
        kind: aogdr > aogdrPrev ? 'positive' : 'negative',
      },
    ];
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return summaryItems ? (
    <Root>
      <Summary items={summaryItems} />
      <StatsAOGDRChart />
      <Box className={statsAOGDRClasses.contentBlock}>
        <Typography variant="h2">
          AOG Dispatch Reliability by Aircraft
        </Typography>
        {isMobile ? <StatsAOGDRTableMobile /> : <StatsAOGDRTable />}
      </Box>
    </Root>
  ) : (
    <Loader type="static" />
  );
};
