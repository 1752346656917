import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsUptime } from '../../../../adapters';
import { Loader } from '../../../../components';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsUptimeChart } from './StatsUptimeChart';
import { StatsUptimeCrewTable } from './StatsUptimeCrewTable';
import { StatsUptimeCrewTableMobile } from './StatsUptimeCrewTableMobile';
import { StatsUptimeTable } from './StatsUptimeTable';
import { StatsUptimeTableMobile } from './StatsUptimeTableMobile';

const PREFIX = 'TSMED-StatsUptime';

const statsUptimeClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsUptimeClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsUptimeClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsUptimeClasses.fieldNegative}`]: {},
  [`.${statsUptimeClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsUptime = () => {
  const { data: statsData } = useQueryData(useQueryStatsUptime);

  const summaryItems: ISummaryItem[] | undefined = useMemo(() => {
    if (!statsData) {
      return;
    }

    const totalHours = statsData.summary.totalValue;
    const TotalPrevHours = statsData.summary.totalPrevValue;
    const totalUptimeHours = statsData.summary.uptimeHours;
    const totalUptimePrevHours = statsData.summary.uptimeHoursPrev;
    const totalCrewDutyAvailabilityHours = statsData.summary.crewAvailableValue;
    const totalCrewDutyAvailabilityPrevHours =
      statsData.summary.crewAvailablePrevValue;
    const uptimeRate = statsData.summary.uptimeRate;
    const uptimeRatePrev = statsData.summary.uptimeRatePrev;
    const crewAvailabilityRate = statsData.summary.crewAvailabilityRate;
    const crewAvailabilityRatePrev = statsData.summary.crewAvailabilityRatePrev;

    return [
      {
        id: 'total-hours',
        label: 'Total Hours',
        primaryValue: statsFormatHours(totalHours),
        secondaryValue: ` / ${statsFormatHours(TotalPrevHours)}`,
      },
      {
        id: 'total-uptime-hours',
        label: 'Total Uptime Hours',
        primaryValue: statsFormatHours(totalUptimeHours),
        secondaryValue: ` / ${statsFormatHours(totalUptimePrevHours)}`,
      },
      {
        id: 'total-uptime-rate',
        label: 'Uptime Rate %',
        primaryValue: statsFormatPercent(uptimeRate),
        secondaryValue: ` / ${statsFormatPercent(uptimeRatePrev)}`,
        icon:
          (uptimeRate === uptimeRatePrev && 'balance') ||
          (uptimeRate < uptimeRatePrev && 'down') ||
          'up',
        kind: uptimeRate > uptimeRatePrev ? 'positive' : 'negative',
      },
      {
        id: 'total-crew-availability-hours',
        label: 'Total Crew Availability Hours',
        primaryValue: statsFormatHours(totalCrewDutyAvailabilityHours),
        secondaryValue: ` / ${statsFormatHours(
          totalCrewDutyAvailabilityPrevHours,
        )}`,
      },
      {
        id: 'total-crew-availability-rate',
        label: 'Crew Availability Rate %',
        primaryValue: statsFormatPercent(crewAvailabilityRate),
        secondaryValue: ` / ${statsFormatPercent(crewAvailabilityRatePrev)}`,
        icon:
          (crewAvailabilityRate === crewAvailabilityRatePrev && 'balance') ||
          (crewAvailabilityRate < crewAvailabilityRatePrev && 'down') ||
          'up',
        kind:
          crewAvailabilityRate < crewAvailabilityRatePrev
            ? 'negative'
            : 'positive',
      },
    ];
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return summaryItems ? (
    <Root>
      <Summary items={summaryItems} />
      <StatsUptimeChart />
      <Box className={statsUptimeClasses.contentBlock}>
        <Typography variant="h2">Uptime by Aircraft</Typography>
        {isMobile ? <StatsUptimeTableMobile /> : <StatsUptimeTable />}
      </Box>
      <Box className={statsUptimeClasses.contentBlock}>
        <Typography variant="h2">Crew Availability by Aircraft</Typography>
        {isMobile ? <StatsUptimeCrewTableMobile /> : <StatsUptimeCrewTable />}
      </Box>
    </Root>
  ) : (
    <Loader type="static" />
  );
};
