import { useMemo } from 'react';

import { useQueryStatsUptime } from '../../../../adapters';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowUptime = MobileTableRow<{
  crewAvailableHours: number;
  totalHours: number;
}>;
export const StatsUptimeCrewTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsUptime);

  const rows: MobileTableRowUptime[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowUptime[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);

    const rows: MobileTableRowUptime[] = Object.values(
      statsData.perAircraft,
    ).map(
      ({
        tailNumber,
        uptimeHours,
        crewAvailableValue,
        totalValue,
        uptimeRate,
        uptimeRatePrev,
        intervals,
      }) => {
        const intervalValues = intervalFields.map(date => ({
          date,
          crewAvailableHours: intervals[date]?.crewAvailableValue ?? 0,
          totalHours: intervals[date]?.totalValue,
        }));

        return {
          id: tailNumber,
          tailNumber,
          crewAvailableHours: crewAvailableValue,
          totalHours: totalValue,
          rate: [uptimeRate, uptimeRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);
  return (
    <StatsTableMobile
      rows={rows}
      rateField={{
        valueFormatter: statsFormatPercent,
      }}
      intervalField={{
        fieldName: 'crewAvailableHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'totalHours',
        valueFormatter: statsFormatHours,
      }}
      summaryRows={[
        {
          field: 'crewAvailableHours',
          label: 'Crew Availability Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'totalHours',
          label: 'Total Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          label: 'Crew Availability Rate',
          valueFormatter: statsFormatPercent,
        },
      ]}
      rateStrategy="asc"
      intervalColumns={['', 'Crew Avail. h / Total h']}
    />
  );
};
