import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsMTTR } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatRate,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowMTTRAog = StatsTableRow<{
  aogHours: number;
  aogCount: number;
}>;

export const StatsMTTRAogTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTTR);

  const {
    intervalColumns,
    rows,
  }: {
    intervalColumns: GridColDef[];
    rows: TableRowMTTRAog[];
  } = useMemo(() => {
    const defaultResult = {
      intervalColumns: [],
      rows: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('MM/DD/YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowMTTRAog[] = Object.values(statsData.perAircraft).map(
      ({ tailNumber, aogCount, aogValue, intervals, aogDur, aogDurPrev }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => {
            const aogHours = intervals[date]?.aogValue ?? 0;
            const aogCount = intervals[date]?.aogCount ?? 0;
            return {
              date,
              aogHours,
              aogCount,
            };
          }),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          aogHours: aogValue,
          aogCount,
          rate: [aogDur, aogDurPrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatRate }}
      intervalField={{
        field: 'aogHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        field: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      summaryColumns={[
        {
          field: 'aogHours',
          label: 'AOG Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'aogCount',
          label: 'AOG Events',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'Avg AOG Duration',
          valueFormatter: statsFormatHours,
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="AOG Hours / AOG Events"
      rateStrategy="desc"
    />
  );
};
