import { useContext, useEffect, useMemo, useState } from 'react';

import { setCasesToAircrafts } from '../../adapters/myAirOps/transformAircraftStatuses';
import { AircraftCard } from '../../components/AircraftCard';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { MapContext } from '../../contexts/MapContext';
import { getCurrentEvent } from '../../helpers/aircrafts';
import {
  AircraftCardItem,
  AircraftStatus,
  CaseType,
  EAircraftEventType,
} from '../../types/new';

import styles from './AircraftCardsPage.module.scss';

interface AircraftCardsPageContentProps {
  aircraftsList: AircraftStatus[];
  activeCases: CaseType[];
  backendNetworkError?: string;
}

export const AircraftCardsPageContent = ({
  aircraftsList,
  activeCases,
  backendNetworkError,
}: AircraftCardsPageContentProps) => {
  const [aircrafts, setAircrafts] = useState<AircraftCardItem[]>([]);

  useEffect(() => {
    if (!backendNetworkError) {
      setAircrafts(setCasesToAircrafts(aircraftsList, activeCases));
    }
  }, [aircraftsList, activeCases, backendNetworkError]);

  const aircraftsSorted = useMemo(
    () =>
      aircrafts
        .reduce<AircraftCardItem[][]>(
          (
            [
              available,
              onTrip,
              offDuty,
              unavailable,
              cleaning,
              scheduledMaint,
              aog,
            ],
            item,
          ) => {
            const currentEvent = getCurrentEvent(item.Events);
            switch (currentEvent.Type) {
              case EAircraftEventType.OnDuty:
                available.push(item);
                break;
              case EAircraftEventType.OnTrip:
                onTrip.push(item);
                break;
              case EAircraftEventType.OffDuty:
                offDuty.push(item);
                break;
              case EAircraftEventType.NotAvailable:
                unavailable.push(item);
                break;
              case 'OnMaintenance':
                if (currentEvent.MaintenanceType === 'AOG') {
                  aog.push(item);
                } else if (currentEvent.MaintenanceType === 'CLN') {
                  cleaning.push(item);
                } else {
                  scheduledMaint.push(item);
                }
                break;
              default:
                break;
            }
            return [
              available,
              onTrip,
              offDuty,
              unavailable,
              cleaning,
              scheduledMaint,
              aog,
            ];
          },
          [[], [], [], [], [], [], []],
        )
        .flat(),
    [aircrafts],
  );

  return (
    <div className={styles.layout}>
      {aircraftsSorted.map(item => (
        <AircraftCard key={item.Id} {...item} count={aircraftsSorted.length} />
      ))}
    </div>
  );
};

export const AircraftCardsPage = () => {
  const { allData, backendNetworkError } = useContext(MapContext);

  return (
    <Layout>
      {(allData && (
        <AircraftCardsPageContent
          aircraftsList={allData.aircrafts.list}
          activeCases={allData.activeCases}
          backendNetworkError={backendNetworkError}
        />
      )) || <Loader />}
      <TechnicalTimers />
    </Layout>
  );
};
