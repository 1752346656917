import { useMemo, ReactNode } from 'react';

import { useQueryAirSigmets, useQueryISigmets } from '../../../adapters';
import { hashCode } from '../../../helpers';
import { getPolygon } from '../../../helpers/pathGeneration';
import { TWeatherFilter, TPoint } from '../../../types';
import { sigmetColor } from '../weather-constants';

import { useWeatherFilter } from './useWeatherFilter';

export const useSigmets = () => {
  const { data: sigmetData } = useQueryAirSigmets();
  const { data: iSigmetData } = useQueryISigmets();

  const { filter } = useWeatherFilter();
  const sigmetElements = useMemo(() => {
    if (!sigmetData) {
      return [];
    }
    return sigmetData
      .filter(
        s =>
          !s.rawText.includes('SIGMET...NONE ') &&
          (s.hazard?.replace(' ', '-') as keyof TWeatherFilter['sigmets']) &&
          filter.sigmets[
            s.hazard?.replace(' ', '-') as keyof TWeatherFilter['sigmets']
          ] &&
          s.severity &&
          s.validTimeTo >= new Date().toISOString() &&
          s.validTimeFrom < new Date().toISOString(),
      )
      .reduce<[ReactNode[], ReactNode[]]>(
        (acc, a, index) => {
          if (!sigmetColor[a.hazard]) {
            console.error('Sigmet: Unhandled hazard type', a);
          } else if (a.lonLatPoints.length) {
            const polygonPoints = getPolygon(a.lonLatPoints);
            const key = hashCode(`${polygonPoints}-${index} `);
            acc[0].push(
              <polygon
                key={`sigmet-${key}`}
                points={polygonPoints}
                fill={`url(#${a.hazard.replaceAll(' ', '_')})`}
                stroke={'rgb(128,0,0)'}
                strokeOpacity={1}
                strokeWidth={1}
              />,
            );
          }

          return acc;
        },
        [[], []],
      );
  }, [filter, sigmetData]);
  const iSigmetElements = useMemo(() => {
    if (!iSigmetData) {
      return [];
    }
    return iSigmetData
      .filter(
        s =>
          (s.hazard?.replace(' ', '-') as keyof TWeatherFilter['sigmets']) &&
          filter.sigmets[
            s.hazard?.replace(' ', '-') as keyof TWeatherFilter['sigmets']
          ] &&
          s.geom === 'AREA',
      )
      .reduce<ReactNode[]>((acc, s, index) => {
        if (!sigmetColor[s.hazard]) {
          console.error('Sigmet: Unhandled hazard type', s);
        } else if (s.coords.length && s.hazard) {
          const points = s.coords.map(c => [c.lon, c.lat] as TPoint);
          const polygonPoints = getPolygon(points);
          const key = hashCode(`${polygonPoints}-${index} `);
          acc.push(
            <polygon
              key={`${key}-isigmet-area`}
              points={polygonPoints}
              fill={`url(#${s.hazard.replaceAll(' ', '_')})`}
              stroke={'rgb(128,0,0)'}
              strokeOpacity={1}
              strokeWidth={1}
            />,
          );
        }

        return acc;
      }, []);
  }, [filter.sigmets, iSigmetData]);

  const sigmetBgPatterns = useMemo(
    () => (
      <defs key={'sigmet-bg-patterns'}>
        {Object.entries(sigmetColor).map(([key, { primary, secondary }]) => (
          <pattern
            id={key.replaceAll(' ', '_')}
            key={key.replaceAll(' ', '_')}
            patternUnits="userSpaceOnUse"
            width="8"
            height="8"
            patternTransform="rotate(-45)"
          >
            <line x1="8" y="0" x2="8" y2="8" stroke={primary} strokeWidth="8" />
            <line
              x1="0"
              y="0"
              x2="0"
              y2="8"
              stroke={secondary}
              strokeWidth="8"
            />
          </pattern>
        ))}
      </defs>
    ),
    [],
  );
  return [sigmetBgPatterns, ...sigmetElements, ...iSigmetElements];
};
