import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

import { fetchAllData } from '../adapters/allData/fetchAllData';
import { REACT_APP_API_FETCH_TRIGGER_SEC } from '../const/const';
import { AppDataErrors, AllDataType } from '../types/new';

interface MapProviderProps {
  children?: ReactNode;
}

type MapContextType = {
  allData?: AllDataType;
  backendNetworkError?: string;
  appDataErrors: AppDataErrors;
  lastUpdateTime: Date | null;
  lastSentTime: Date | null;
  lastReceiveTime: Date | null;
};

const initialContextData: MapContextType = {
  lastUpdateTime: null,
  appDataErrors: {},
  lastSentTime: null,
  lastReceiveTime: null,
};

export const MapContext = createContext<MapContextType>(initialContextData);

export const MapProvider = ({ children }: MapProviderProps) => {
  const [allData, setAllData] = useState<AllDataType>();
  const [backendNetworkError, setBackendNetworkError] = useState<string>();
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());

  const [appDataErrors, setAppDataErrors] = useState<AppDataErrors>({});
  const [lastSentTime, setLastSentTime] = useState<Date | null>(null);
  const [lastReceiveTime, setLastReceiveTime] = useState<Date | null>(null);

  useEffect(() => {
    const doFetch = async (force = false) => {
      // fetch only if "force"==true or second matches 5
      if (
        !force &&
        new Date().getSeconds() !== REACT_APP_API_FETCH_TRIGGER_SEC
      ) {
        return;
      }

      setLastSentTime(new Date());
      try {
        const { allData, backendNetworkError } = await fetchAllData();

        if (!backendNetworkError) {
          setAllData(allData);
          setLastReceiveTime(new Date());
          setBackendNetworkError(undefined);
          setLastUpdateTime(new Date());
          setAppDataErrors({});
        } else {
          setBackendNetworkError(backendNetworkError);
          setAppDataErrors({
            backendNetwork: {
              backendNetwork: {
                label: 'backendNetwork',
                errorMessages: [backendNetworkError],
              },
            },
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setBackendNetworkError('Error fetching data.');
        setAppDataErrors({
          backendNetwork: {
            backendNetwork: {
              label: 'backendNetwork',
              errorMessages: ['Error fetching data.'],
            },
          },
        });
      }
    };
    doFetch(true);

    // interval
    const interval = setInterval(doFetch, 1000);
    return () => clearInterval(interval);
  }, [setLastSentTime, setLastReceiveTime]);

  const providerValue = useMemo(
    () => ({
      allData,
      backendNetworkError,
      lastUpdateTime,
      appDataErrors,
      lastSentTime,
      lastReceiveTime,
    }),
    [
      allData,
      backendNetworkError,
      lastUpdateTime,
      appDataErrors,
      lastSentTime,
      lastReceiveTime,
    ],
  );

  return (
    <MapContext.Provider value={providerValue}>{children}</MapContext.Provider>
  );
};
