import classnames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';

import { AlertsColumn } from '../../components/AlertsColumn';
import { DataErrorsCountTile } from '../../components/ErrorsPageComponents/DataErrorsCountTile';
import { DataErrorsList } from '../../components/ErrorsPageComponents/DataErrorsList';
import { InfoTile } from '../../components/ErrorsPageComponents/InfoTile';
import { LastUpdate } from '../../components/ErrorsPageComponents/LastUpdate';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { TripCard } from '../../components/TripCard';
import { MAX_CASE_COLUMN_ITEMS, MAX_CASE_COLUMNS } from '../../const/const';
import { MapContext } from '../../contexts/MapContext';
import {
  filterCasesByStatus,
  splitNewCasesIntoColumns,
} from '../../helpers/caseHelper';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { useElementRef } from '../../hooks/useElementRef';
import { AppDataErrors, CaseType, NewCaseType } from '../../types/new';

import styles from './ErrorsPage.module.scss';

const showPlaceholder = (
  newCasesLength = 0,
  extraCasesLength = 0,
  maxCases = 2,
): boolean => {
  if (newCasesLength) {
    return extraCasesLength < maxCases - 1;
  }

  return extraCasesLength < maxCases;
};

interface ErrorsPageContentProps {
  cases: CaseType[];
  newCases: NewCaseType[];
  dataErrors: AppDataErrors;
  networkErrors: AppDataErrors;
  lastUpdateTime: Date;
  backendNetworkError?: string;
}

export const ErrorsPageContent = ({
  cases,
  newCases,
  dataErrors,
  networkErrors,
  lastUpdateTime,
  backendNetworkError = '',
}: ErrorsPageContentProps) => {
  const [networkErrorsCount, setNetworkErrorsCount] = useState(0);
  const [dataErrorsCount, setDataErrorsCount] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const { ref, element } = useElementRef('errorsRightSide');

  useEffect(() => {
    let curDataErrorsCount = 0;
    for (const objType in dataErrors) {
      if (dataErrors[objType]) {
        curDataErrorsCount += Object.keys(dataErrors[objType]).length;
      }
    }
    setDataErrorsCount(curDataErrorsCount);

    let curNetworkErrorsCount = 0;
    for (const objType in networkErrors) {
      if (networkErrors[objType]) {
        curNetworkErrorsCount += Object.keys(networkErrors[objType]).length;
      }
    }
    setNetworkErrorsCount(curNetworkErrorsCount);
  }, [dataErrors, networkErrors]);

  useEffect(() => {
    if (element) {
      setHasScroll(element.scrollHeight > element.clientHeight);
    }
  }, [element, dataErrors]);

  const extraCases = useMemo(() => {
    const filtered = filterCasesByStatus(cases, ['Returning to Base']);
    const extraCasesStartIndex = MAX_CASE_COLUMN_ITEMS * MAX_CASE_COLUMNS;
    return cases
      .slice(extraCasesStartIndex, filtered.cases.length)
      .concat(filtered.excluded);
  }, [cases]);

  const { extra: extraNewCases } = useMemo(
    () => splitNewCasesIntoColumns(newCases),
    [newCases],
  );

  const { start, pause } = useAutoScroll({ element, hasScroll });

  const onMouseEnter = () => pause();
  const onMouseLeave = () => start();

  const handleScroll = () => {
    if (hasScroll && element) {
      setScrolled(element.scrollTop > 0);
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.leftSide}>
        <div className={styles.topInfo}>
          {backendNetworkError ? (
            <InfoTile
              icon={'cloudError'}
              largeCaption={'BACKEND'}
              smallCaption={'NETWORK Error'}
              additionalClassName={'error'}
            />
          ) : (
            <InfoTile
              icon={'cloudOk'}
              largeCaption={'BACKEND'}
              smallCaption={'NETWORK OK'}
            />
          )}
          {dataErrorsCount ? (
            <DataErrorsCountTile
              dataErrorsCount={dataErrorsCount}
              backendNetworkError={backendNetworkError}
            />
          ) : (
            <InfoTile
              icon={'shield'}
              largeCaption={'ALL GOOD'}
              smallCaption={'SYSTEM STATUS'}
            />
          )}
        </div>
        <LastUpdate
          lastUpdateTime={lastUpdateTime}
          backendNetworkError={backendNetworkError}
          networkErrorsCount={networkErrorsCount}
        />
        <DataErrorsList
          backendNetworkError={backendNetworkError}
          networkErrors={networkErrors}
          dataErrors={dataErrors}
        />
      </div>
      <div
        className={classnames(styles.rightSideWrapper, {
          [styles.rightSideWrapperFadeBefore]: isScrolled,
          [styles.rightSideWrapperFadeAfter]: hasScroll,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          id="errorsRightSide"
          ref={ref}
          className={styles.rightSide}
          style={{
            justifyContent: extraNewCases.length ? 'flex-start' : 'center',
          }}
          onScroll={handleScroll}
        >
          {!!extraNewCases.length && (
            <AlertsColumn
              direction="blank"
              newCases={extraNewCases}
              additionalClassName={'errorsPageStyles'}
            />
          )}
          {!!extraCases.length && (
            <div className={styles.extraCases}>
              {extraCases.map(curCase => (
                <div className={styles.case} key={curCase.label}>
                  <TripCard
                    additionalClassName="errorsPageStyles"
                    theCase={curCase}
                  />
                </div>
              ))}
            </div>
          )}
          {showPlaceholder(extraNewCases.length, extraCases.length, 3) && (
            <div className={styles.placeholderList}>
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
            </div>
          )}
          {showPlaceholder(extraNewCases.length, extraCases.length, 2) && (
            <div className={styles.placeholderList}>
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
            </div>
          )}
          {!extraNewCases.length && !extraCases.length && (
            <div className={styles.placeholderList}>
              <div className={styles.placeholder} />
              <div className={styles.placeholder} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ErrorsPage = () => {
  const { allData, backendNetworkError, lastUpdateTime } =
    useContext(MapContext);

  return (
    (allData && lastUpdateTime && (
      <Layout>
        <ErrorsPageContent
          cases={allData.activeCases}
          newCases={allData.newCases}
          dataErrors={allData.dataErrors}
          networkErrors={allData.networkErrors}
          lastUpdateTime={lastUpdateTime}
          backendNetworkError={backendNetworkError}
        />
        <TechnicalTimers />
      </Layout>
    )) || <Loader />
  );
};
