import { Logout as LogoutIcon, Home as HomeIcon } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ComboPageSVG } from '../../assets/comboPageIcon.svg';
import { ReactComponent as HubSVG } from '../../assets/hub.svg';
import { ReactComponent as MonitorMenuSVG } from '../../assets/monitorMenuIcon.svg';
import { ReactComponent as PlaneMenuSVG } from '../../assets/planeMenuIcon.svg';
import { ReactComponent as PlannerMenuSVG } from '../../assets/plannerMenuIcon.svg';
import { ReactComponent as StatisticsSVG } from '../../assets/statistics.svg';
import { ReactComponent as StatsLogoSVG } from '../../assets/statsLogo.svg';
import { ReactComponent as WeatherSVG } from '../../assets/weather.svg';
import { allRoutes as routes } from '../../const/routes';
import { useSsoUserGroupAccess } from '../../hooks/useUserGroupAccess';
import { PageDropdown, type PageDropdownProps } from '../PageDropdown';

const PREFIX = 'TSMED-LayoutPage';
export const layoutPageClasses = {
  root: `${PREFIX}-root`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints, palette } }) => ({}));

const commandCenterMenuItems: PageDropdownProps['items'] = [
  {
    id: routes.COMBO_PAGE,
    icon: <ComboPageSVG />,
    title: 'Combo Screen',
    type: 'route',
  },
  {
    id: routes.AIRCRAFTS_MAP,
    icon: <PlaneMenuSVG />,
    type: 'route',
    title: 'Air Fleet Tracker',
  },
  {
    id: routes.AIRCRAFTS_CARDS,
    icon: <PlannerMenuSVG />,
    type: 'route',
    title: 'Air Fleet Planner',
  },
  {
    id: routes.ERRORS,
    icon: <MonitorMenuSVG />,
    type: 'route',
    title: 'System Monitor',
  },
  {
    id: routes.STATISTICS,
    icon: <StatisticsSVG />,
    type: 'route',
    title: 'Statistics',
  },
  {
    id: routes.HUBS_REPORT,
    icon: <HubSVG />,
    type: 'route',
    title: 'Hubs Report',
  },
  {
    id: routes.WEATHER,
    icon: <WeatherSVG />,
    type: 'route',
    title: 'Aviation Weather',
  },
];
const statsMenuItems: PageDropdownProps['items'] = [
  {
    id: routes.STATS,
    icon: <StatsLogoSVG />,
    type: 'route',
    title: 'TMDX Statistics',
  },
];
export const Layout = ({ children }: PropsWithChildren) => {
  const { status, grantedGroups } = useSsoUserGroupAccess();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSwitch = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );
  const menuItems = useMemo(() => {
    if (status === 'pending') {
      return [];
    }

    const items: PageDropdownProps['items'] = [
      {
        id: routes.START_PAGE,
        icon: <HomeIcon />,
        type: 'route',
        title: 'Start Page',
      },
      {
        type: 'delimiter',
      },
    ];
    if (
      grantedGroups.includes('CommandCenter') ||
      grantedGroups.includes('Admin')
    ) {
      items.push(
        ...([
          ...commandCenterMenuItems,
          {
            type: 'delimiter',
          },
        ] as PageDropdownProps['items']),
      );
    }
    if (
      grantedGroups.includes('Statistics') ||
      grantedGroups.includes('Admin')
    ) {
      items.push(
        ...([
          ...statsMenuItems,
          {
            type: 'delimiter',
          },
        ] as PageDropdownProps['items']),
      );
    }
    items.push({
      id: routes.LOGOUT,
      icon: <LogoutIcon />,
      type: 'route',
      title: 'Logout',
    });
    return items;
  }, [grantedGroups, status]);

  return (
    <Root id="layout">
      <PageDropdown
        id="mainMenu"
        items={menuItems}
        value={location.pathname}
        onChange={handleSwitch}
        fixed
      />
      {children}
    </Root>
  );
};
