import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsMTTR } from '../../../../adapters/stats/useQueryStatsMTTR';
import { Loader } from '../../../../components';
import { statsFormatCount, statsFormatHours } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsMTTRAogTable } from './StatsMTTRAogTable';
import { StatsMTTRAogTableMobile } from './StatsMTTRAogTableMobile';
import { StatsMTTRChart } from './StatsMTTRChart';
import { StatsMTTRRepairTable } from './StatsMTTRRepairTable';
import { StatsMTTRRepairTableMobile } from './StatsMTTRRepairTableMobile';

const PREFIX = 'TSMED-StatsMTTRTab';

const statsMTTRClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsMTTRClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsMTTRClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsMTTRClasses.fieldNegative}`]: {},
  [`.${statsMTTRClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsMTTR = () => {
  const { data: statsData } = useQueryData(useQueryStatsMTTR);

  const summaryItems: ISummaryItem[] | undefined = useMemo(() => {
    if (!statsData) {
      return;
    }

    const totalRepairHours = statsData.summary.repairValue;
    const totalAogCount = statsData.summary.aogCount;
    const totalAogHours = statsData.summary.aogValue;
    const totalMttrRepair = statsData.summary.mttr;
    const totalAogDur = statsData.summary.aogDur;
    const totalPrevRepairHours = statsData.summary.repairPrevValue;
    const totalPrevAogCount = statsData.summary.aogPrevCount;
    const totalPrevAogHours = statsData.summary.aogPrevValue;
    const totalPrevMttrRepair = statsData.summary.mttrPrev;
    const totalPrevAogDur = statsData.summary.aogDurPrev;

    return [
      {
        id: 'total-repair-hours',
        label: 'Total AOG Repair Hours',
        primaryValue: statsFormatHours(totalRepairHours),
        secondaryValue: ` / ${statsFormatHours(totalPrevRepairHours)}`,
      },
      {
        id: 'total-aog-count',
        label: 'Total AOG Events',
        primaryValue: statsFormatCount(totalAogCount),
        secondaryValue: ` / ${statsFormatCount(totalPrevAogCount)}`,
      },
      {
        id: 'mttr-repair',
        label: 'MTTR',
        primaryValue: statsFormatHours(totalMttrRepair),
        secondaryValue: ` / ${statsFormatHours(totalPrevMttrRepair)}`,
        icon:
          (totalMttrRepair === totalPrevMttrRepair && 'balance') ||
          (totalMttrRepair < totalPrevMttrRepair && 'down') ||
          'up',
        kind: totalMttrRepair > totalPrevMttrRepair ? 'negative' : 'positive',
      },
      {
        id: 'total-aog-hours',
        label: 'Total AOG Hours',
        primaryValue: statsFormatHours(totalAogHours),
        secondaryValue: ` / ${statsFormatHours(totalPrevAogHours)}`,
      },
      {
        id: 'mttr-aog',
        label: 'Average AOG Duration',
        primaryValue: statsFormatHours(totalAogDur),
        secondaryValue: ` / ${statsFormatHours(totalPrevAogDur)}`,
        icon:
          (totalAogDur === totalPrevAogDur && 'balance') ||
          (totalAogDur < totalPrevAogDur && 'down') ||
          'up',
        kind: totalAogDur > totalPrevAogDur ? 'negative' : 'positive',
      },
    ];
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return summaryItems ? (
    <Root>
      <Summary items={summaryItems} />
      <StatsMTTRChart />
      <Box className={statsMTTRClasses.contentBlock}>
        <Typography variant="h2">MTTR by Aircraft</Typography>
        {isMobile ? <StatsMTTRRepairTableMobile /> : <StatsMTTRRepairTable />}
      </Box>
      <Box className={statsMTTRClasses.contentBlock}>
        <Typography variant="h2">Average AOG Duration by Aircraft</Typography>
        {isMobile ? <StatsMTTRAogTableMobile /> : <StatsMTTRAogTable />}
      </Box>
    </Root>
  ) : (
    <Loader type="static" />
  );
};
