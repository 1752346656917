const START_PAGE = '/';
const MAIN_MAP = '/main_map';
const COMBO_PAGE = '/combo_page';
const AIRCRAFTS_MAP = '/aircrafts_map';
const AIRCRAFTS_CARDS = '/aircrafts_cards';
const ERRORS = '/errors';
const STATISTICS = '/statistics';
const HUBS_REPORT = '/hubs_report';
const WEATHER = '/weather';
const STATS = '/stats/*';
const STATS_FR = '/stats/fr';
const STATS_UPTIME = '/stats/uptime';
const STATS_DOWNTIME = '/stats/downtime';
const STATS_MTTR = '/stats/mttr';
const STATS_MTBA = '/stats/mtba';
const STATS_AOGDR = '/stats/aogdr';

const PAGE_NOT_FOUND = '/404';
const NO_ACCESS = '/403';
const LOGOUT = '/logout';

export const allRoutes = {
  START_PAGE,
  MAIN_MAP,
  COMBO_PAGE,
  AIRCRAFTS_MAP,
  AIRCRAFTS_CARDS,
  ERRORS,
  STATISTICS,
  PAGE_NOT_FOUND,
  LOGOUT,
  HUBS_REPORT,
  WEATHER,
  STATS,
  STATS_FR,
  STATS_UPTIME,
  STATS_DOWNTIME,
  STATS_MTTR,
  STATS_MTBA,
  STATS_AOGDR,
  NO_ACCESS,
};
