import {
  Build as BuildIcon,
  CleaningServicesOutlined as CleaningServicesOutlinedIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { useMemo } from 'react';

import { ReactComponent as PlaneSVG } from '../../../assets/plane.svg';
import { useElementRef } from '../../../hooks/useElementRef';
import { AircraftOnAirportType } from '../../../types/new';
import styles from '../overlays.module.scss';

interface IconsProps extends AircraftOnAirportType {
  isSetElementRef?: boolean;
}

export const AircraftOnMaintenanceIcons = ({
  isSetElementRef = false,
  ...item
}: IconsProps) => {
  const { ref } = useElementRef<HTMLDivElement>(`${item.Id}-aircraft-icons`);

  const aircraftsMapStyles = useMemo(
    () =>
      classNames(
        styles.aircraftMapOnMaintenance,
        styles[`type-${item.MaintenanceType}`],
      ),
    [item.MaintenanceType],
  );
  return (
    <div
      ref={isSetElementRef ? ref : null}
      className={classNames(styles.aircraftOnMap)}
    >
      <div key={item.Id} className={classNames(styles.aircraftInfo)}>
        <span className={classNames(styles.registration, aircraftsMapStyles)}>
          {item.Registration}
        </span>
        <div className={styles.aircraftAirportInfo}>
          {Boolean(item.Airport?.IATA ?? item.Airport?.ICAO) && (
            <span
              className={classNames(styles.registration, aircraftsMapStyles)}
              style={{ opacity: 0.7 }}
            >
              {item.Airport?.IATA ?? item.Airport?.ICAO}
            </span>
          )}
          <div
            className={classNames(
              styles.aircraftMapIconOnMaintenance,
              aircraftsMapStyles,
            )}
          >
            <PlaneSVG />
          </div>
          <div className={classNames(styles.aircraftTime, aircraftsMapStyles)}>
            {item.MaintenanceType === 'CLN' ? (
              <CleaningServicesOutlinedIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
