import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsFR } from '../../../../adapters';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatRate,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowFRCount = StatsTableRow<{
  flightCount: number;
  aogCount: number;
}>;

export const StatsFRCountsTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsFR);
  const {
    rows,
    intervalColumns,
  }: {
    rows: TableRowFRCount[];
    intervalColumns: GridColDef[];
  } = useMemo(() => {
    const defaultResult: {
      rows: TableRowFRCount[];
      intervalColumns: GridColDef[];
    } = {
      rows: [],
      intervalColumns: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('MM/DD/YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowFRCount[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        flightCount,
        aogCount,
        aogFrequencyRatePerFlights,
        aogFrequencyRatePerFlightsPrev,
        intervals,
      }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => {
            const flightCount = intervals[date]?.flightCount ?? 0;
            const aogCount = intervals[date]?.aogCount ?? 0;
            return {
              date,
              flightCount,
              aogCount,
            };
          }),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          flightCount,
          aogCount,
          rate: [
            aogFrequencyRatePerFlights,
            aogFrequencyRatePerFlightsPrev,
          ] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatHours }}
      intervalField={{ field: 'flightCount', valueFormatter: statsFormatCount }}
      intervalSecondaryField={{
        field: 'aogCount',
        valueFormatter: statsFormatCount,
      }}
      summaryColumns={[
        {
          field: 'flightCount',
          label: 'Flights',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'aogCount',
          label: 'AOG Events',
          valueFormatter: statsFormatCount,
        },
        {
          field: 'rate',
          label: 'Rate',
          valueFormatter: statsFormatRate,
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="Flights / AOG Events"
      rateStrategy="desc"
    />
  );
};
