import type { QueryStatus } from '@tanstack/react-query';
import { intersection } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryGrantedGroups } from '../adapters';
import { allRoutes } from '../const/routes';
import type { SsoUserGroup } from '../types';

const statsRootRoute = allRoutes.STATS.replace('*', '');
const defaultPages = [statsRootRoute, allRoutes.START_PAGE];
const initialGrantedGroups = localStorage.getItem('grantedGroups');
export const useSsoUserGroupAccess = (
  groups?: SsoUserGroup[],
): {
  currentAccess?: boolean;
  grantedGroups: SsoUserGroup[];
  defaultPage: string;
  isDefaultPage: boolean;
  status: QueryStatus;
} => {
  const { pathname } = useLocation();
  // const msalStatePath = localStorage.getItem('TSMED_SSO_STATE_PATH');
  // const targetPath = msalStatePath ?? pathname;
  const isStandalone = useMemo(() => {
    const isStandalone = window.matchMedia(
      '(display-mode: standalone)',
    ).matches;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (navigator as any)?.standalone || isStandalone;
  }, []);

  const [grantedGroups, setGrantedGroups] = useState(
    (initialGrantedGroups && JSON.parse(initialGrantedGroups)) || [],
  );

  const { status, data } = useQueryGrantedGroups();

  useEffect(() => {
    if (data && data.length > 0) {
      setGrantedGroups(data);
      localStorage.setItem('grantedGroups', JSON.stringify(data));
    }
  }, [data]);

  const isDefaultPage = defaultPages.includes(pathname);

  const defaultPage = useMemo(() => {
    if (!grantedGroups) {
      return;
    }
    if (grantedGroups.includes('Admin')) {
      return (isStandalone && statsRootRoute) || allRoutes.START_PAGE;
    }
    if (isStandalone) {
      if (grantedGroups.includes('Statistics')) {
        return statsRootRoute;
      }
      if (grantedGroups.includes('CommandCenter')) {
        return allRoutes.START_PAGE;
      }
    }
    if (grantedGroups.includes('CommandCenter')) {
      return allRoutes.START_PAGE;
    }
    if (grantedGroups.includes('Statistics')) {
      return statsRootRoute;
    }
  }, [grantedGroups, isStandalone]);

  const currentAccess = useMemo(
    () => groups && !!intersection(grantedGroups, ['Admin', ...groups]).length,
    [grantedGroups, groups],
  );

  return {
    defaultPage,
    grantedGroups,
    isDefaultPage,
    status,
    currentAccess,
  };
};
