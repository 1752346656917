import { Box, styled } from '@mui/material';
import classnames from 'classnames';
import { ReactNode, useState } from 'react';

import { ReactComponent as DotsSVG } from '../../assets/dots.svg';

const PREFIX = 'TSMED-PageDropdown';

export const pageDropdownClasses = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  iconActive: `${PREFIX}-iconActive`,
  menu: `${PREFIX}-menu`,
  menuDelimiter: `${PREFIX}-menuDelimiter`,
  menuItem: `${PREFIX}-menuItem`,
  menuItemActive: `${PREFIX}-menuItemActive`,
  menuIcon: `${PREFIX}-menuIcon`,
  itemLabel: `${PREFIX}-itemLabel`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints, palette } }) => ({
  [`&.${pageDropdownClasses.root}`]: {
    position: 'absolute',
    top: spacing(1.5),
    right: spacing(1),
    zIndex: 1000,
    [breakpoints.down('sm')]: {
      display: 'none',
    },

    [`.${pageDropdownClasses.icon}`]: {
      width: 40,
      height: 28,
      boxShadow: '0 14px 40px 0 #000000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#1d2a3780',
      color: '#7f8083',
      borderRadius: spacing(0.5),
      transition: 'background-color, color 0.15s linear',
      cursor: 'pointer',
      marginBottom: spacing(0.5),
      [`&.${pageDropdownClasses.iconActive}`]: {
        backgroundColor: '#292d2e',
        color: '#dddddd',
      },
    },

    [`& .${pageDropdownClasses.menu}`]: {
      borderRadius: spacing(0.5),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: 0,
      width: 'max-content',
      backgroundColor: '#3a3f42',
    },

    [`.${pageDropdownClasses.menuItem}`]: {
      display: 'flex',
      alignItems: 'center',
      height: spacing(4),
      gap: spacing(1),
      padding: '6px 12px 6px 8px',
      color: '#ffffff',
      transition: 'background-color 0.15s linear',
      cursor: 'pointer',
      [`&.${pageDropdownClasses.menuDelimiter}`]: {
        margin: spacing(0.5, 1),
        flexShrink: 0,
        borderWidth: 'thin 0 0 0',
        borderStyle: 'solid',
        borderColor: `${palette.grey[200]}80`,
        alignSelf: 'stretch',
        width: 'auto',
        height: 0,
        padding: 0,
      },

      [`&.${pageDropdownClasses.menuItemActive}`]: {
        backgroundColor: '#292d2e',
        cursor: 'default',
        pointerEvents: 'none',
      },
      [`.${pageDropdownClasses.menuIcon}`]: {
        flexShrink: 0,
        width: spacing(2),
        height: spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [`& > svg`]: {
          fill: 'currentColor',
          width: '100%',
          filter: 'grayscale(1) brightness(0) invert(1)',
        },
      },

      [`& .${pageDropdownClasses.iconActive}`]: {
        backgroundColor: '#292d2e',
        cursor: 'default',
        pointerEvents: 'none',
      },

      [`&:hover`]: {
        backgroundColor: '#292d2e',
      },
    },
  },
}));

export interface PageDropdownProps {
  id: string;
  items: Array<
    | {
        id: string;
        icon: ReactNode;
        title: string;
        type: 'route';
      }
    | {
        type: 'delimiter';
      }
  >;
  fixed?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const PageDropdown = ({
  id,
  items,
  value,
  onChange,
  fixed = false,
}: PageDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = () => setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);

  const onValueChange = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <Root
      className={pageDropdownClasses.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        ...((fixed && { position: 'fixed' }) || {}),
      }}
    >
      <Box
        className={classnames(pageDropdownClasses.icon, {
          [pageDropdownClasses.iconActive]: isOpen,
        })}
      >
        <DotsSVG width="20px" />
      </Box>
      {isOpen && (
        <Box className={pageDropdownClasses.menu}>
          {items.map((item, index) =>
            item.type === 'delimiter' ? (
              <Box
                key={`delimiter-${Math.random() * 1000}`}
                className={classnames(
                  pageDropdownClasses.menuItem,
                  pageDropdownClasses.menuDelimiter,
                )}
              />
            ) : (
              <Box
                key={`${id}-${item.id}`}
                className={classnames(pageDropdownClasses.menuItem, {
                  [pageDropdownClasses.menuItemActive]: value === item.id,
                })}
                onClick={() => onValueChange(item.id)}
              >
                <Box className={pageDropdownClasses.menuIcon}>{item.icon}</Box>
                <Box
                  component={'span'}
                  className={pageDropdownClasses.itemLabel}
                >
                  {item.title}
                </Box>
              </Box>
            ),
          )}
        </Box>
      )}
    </Root>
  );
};
