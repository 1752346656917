import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsFR } from '../../../../adapters';
import { Loader } from '../../../../components';
import {
  statsFormatCount,
  statsFormatHours,
  statsFormatRate,
} from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsFRChart } from './StatsFRChart';
import { StatsFRCountsTable } from './StatsFRCountsTable';
import { StatsFRCountsTableMobile } from './StatsFRCountsTableMobile';
import { StatsFRHoursTable } from './StatsFRHoursTable';
import { StatsFRHoursTableMobile } from './StatsFRHoursTableMobile';

const PREFIX = 'TSMED-statsFRTab';

const statsFrequencyClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsFrequencyClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsFrequencyClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsFrequencyClasses.fieldNegative}`]: {},
  [`.${statsFrequencyClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsFR = () => {
  const { data: statsData } = useQueryData(useQueryStatsFR);
  const summaryItems: ISummaryItem[] | undefined = useMemo(() => {
    if (!statsData) {
      return;
    }
    const totalAogEventsCount = statsData.summary.aogCount;
    const totalFlightEventsCount = statsData.summary.flightCount;
    const totalFlightsHours = statsData.summary.flightValue;
    const totalAogFRPerFlights = statsData.summary.aogFrequencyRatePerFlights;
    const totalAogFRPerHours = statsData.summary.aogFrequencyRatePerHours;
    const totalPrevAogEventsCount = statsData.summary.aogPrevCount;
    const totalPrevFlightEventsCount = statsData.summary.flightPrevCount;
    const totalPrevFlightsHours = statsData.summary.flightPrevValue;
    const totalPrevAogFRPerFlights =
      statsData.summary.aogFrequencyRatePerFlightsPrev;
    const totalPrevAogFRPerHours =
      statsData.summary.aogFrequencyRatePerHoursPrev;

    return [
      {
        id: 'total-flight-hours',
        label: 'Total Flight Hours',
        primaryValue: statsFormatHours(totalFlightsHours),
        secondaryValue: ` / ${statsFormatHours(totalPrevFlightsHours)}`,
      },
      {
        id: 'total-aog-events',
        label: 'Total AOG Events',
        primaryValue: statsFormatCount(totalAogEventsCount),
        secondaryValue: ` / ${statsFormatCount(totalPrevAogEventsCount)}`,
      },
      {
        id: 'aog-frequency-rate-per-flight-hours',
        label: 'AOG Frequency Rate per 1000 Flight Hours',
        icon:
          (totalAogFRPerHours === totalPrevAogFRPerHours && 'balance') ||
          (totalAogFRPerHours < totalPrevAogFRPerHours && 'down') ||
          'up',
        kind:
          totalAogFRPerHours < totalPrevAogFRPerHours ? 'positive' : 'negative',
        primaryValue: statsFormatRate(totalAogFRPerHours),
        secondaryValue: ` / ${statsFormatRate(totalPrevAogFRPerHours)}`,
      },
      {
        id: 'total-flights',
        label: 'Total Flights',
        primaryValue: statsFormatCount(totalFlightEventsCount),
        secondaryValue: ` / ${statsFormatCount(totalPrevFlightEventsCount)}`,
      },
      {
        id: 'aog-frequency-rate-per-flights',
        label: 'AOG Frequency Rate per 1000 Flights',
        icon:
          (totalAogFRPerFlights === totalPrevAogFRPerFlights && 'balance') ||
          (totalAogFRPerFlights < totalPrevAogFRPerFlights && 'down') ||
          'up',
        kind:
          totalAogFRPerFlights < totalPrevAogFRPerFlights
            ? 'positive'
            : 'negative',
        primaryValue: statsFormatRate(totalAogFRPerFlights),
        secondaryValue: ` / ${statsFormatRate(totalPrevAogFRPerFlights)}`,
      },
    ];
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return summaryItems ? (
    <Root>
      <Summary items={summaryItems} />
      <StatsFRChart />
      <Box className={statsFrequencyClasses.contentBlock}>
        <Typography variant="h2">
          AOG Frequency by 1000 Flight Hours per Aircraft
        </Typography>
        {isMobile ? <StatsFRHoursTableMobile /> : <StatsFRHoursTable />}
      </Box>
      <Box className={statsFrequencyClasses.contentBlock}>
        <Typography variant="h2">
          AOG Frequency by 1000 Flights per Aircraft
        </Typography>
        {isMobile ? <StatsFRCountsTableMobile /> : <StatsFRCountsTable />}
      </Box>
    </Root>
  ) : (
    <Loader type="static" />
  );
};
