import { styled, useMediaQuery, useTheme } from '@mui/material';
import { axisClasses } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useQueryStatsFR } from '../../../../adapters';
import { useQueryData } from '../useQueryData';

const PREFIX = 'TSMED-StatsFRChart';

const Root = styled(LineChart, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints } }) => ({
  padding: spacing(2),
  background: '#0F151C',
  [`.${axisClasses.directionX}`]: {
    [`.${axisClasses.tickLabel}`]: {
      translate: spacing(0, 1),
      [breakpoints.down('sm')]: {
        rotate: '-45deg',
        translate: spacing(-3, 4),
      },
    },
  },
}));

export const StatsFRChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { palette } = useTheme();

  const { data: statsData } = useQueryData(useQueryStatsFR);

  const { countsData, xLabels, hoursData } = useMemo(() => {
    if (!statsData) {
      return {
        countsData: [],
        hoursData: [],
        xLabels: [],
      };
    }

    return Object.entries(statsData.perDate)
      .sort((a, b) => (a[0] < b[0] ? -1 : 1))
      .map(
        ([date, { aogFrequencyRatePerFlights, aogFrequencyRatePerHours }]) => ({
          date: dayjs(date).format('MM/DD/YYYY'),
          aogFrequencyRatePerFlights,
          aogFrequencyRatePerHours,
        }),
      )
      .reduce<{ countsData: number[]; hoursData: number[]; xLabels: string[] }>(
        (
          acc,
          { aogFrequencyRatePerFlights, aogFrequencyRatePerHours, date },
        ) => {
          acc.countsData.push(aogFrequencyRatePerFlights);
          acc.hoursData.push(aogFrequencyRatePerHours);
          acc.xLabels.push(date);
          return acc;
        },
        { countsData: [], hoursData: [], xLabels: [] },
      );
  }, [statsData]);

  return (
    <Root
      slotProps={{
        legend: {
          labelStyle: {
            fontSize: 14,
            fill: palette.grey[400],
          },
          direction: 'row',
          position: { vertical: 'bottom', horizontal: 'left' },
          itemMarkHeight: 8,
          itemMarkWidth: 8,
          itemGap: 16,
          markGap: 8,
        },

        axisLabel: {
          fill: palette.mapBlue[700],
        },
      }}
      tooltip={{
        trigger: 'none',
      }}
      disableLineItemHighlight
      disableAxisListener
      axisHighlight={{
        x: 'none',
        y: 'none',
      }}
      margin={{
        bottom: isMobile ? 176 : 96,
      }}
      height={430}
      series={[
        {
          data: countsData,
          label: 'AOG Frequency Rate per 1000 Flights',
          color: palette.orange[500],
        },
        {
          data: hoursData,
          label: 'AOG Frequency Rate per 1000 Flight Hours',
          color: palette.violet[500],
        },
      ]}
      grid={{
        vertical: true,
        horizontal: true,
      }}
      xAxis={[
        {
          scaleType: 'point',
          data: xLabels,
          dataKey: 'date',
          min: 0,
        },
      ]}
      yAxis={[
        {
          scaleType: 'linear',
          data: countsData,
          min: 0,
        },
        {
          scaleType: 'linear',
          data: hoursData,
          min: 0,
        },
      ]}
    />
  );
};
