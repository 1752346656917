import classnames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Loader.module.scss';

interface LoaderProps {
  type?: 'absolute' | 'static';
}

export const Loader = ({
  type = 'absolute',
  children,
}: PropsWithChildren<LoaderProps>) => (
  <div
    className={classnames(styles.loaderWrapper, {
      [styles.loaderAbsolute]: type === 'absolute',
    })}
  >
    <div className={styles.loader}>
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
);
