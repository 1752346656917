import { Box, styled } from '@mui/material';
import {
  DataGridPro,
  type GridColDef,
  type GridRenderCellParams,
  gridClasses,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { SortAscIcon, SortDescIcon, SortIcon } from '../../../components';
import { ratedColor } from '../stat-constants';

import { statsTrendedValueClasses, TrendedValue } from './TrendedValue';

const PREFIX = 'TSMED-StatsTable';

const statsTableClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  rateCell: `${PREFIX}-rateCell`,
  intervalCell: `${PREFIX}-intervalCell`,
  intervalCellDelimiter: `${PREFIX}-intervalCellDelimiter`,
  intervalCellPrimary: `${PREFIX}-intervalCellPrimary`,
  intervalCellSecondary: `${PREFIX}-intervalCellSecondary`,
};

const Root = styled(DataGridPro, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  fontSize: '14px',
  [`.${gridClasses.columnHeaders}`]: {
    flexDirection: 'column-reverse',
  },
  [`.${gridClasses.columnHeaderTitleContainer}`]: {
    justifyContent: 'space-between',
  },
  [`.${statsTableClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },

  [`.${statsTableClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsTableClasses.fieldNegative}`]: {},
  [`.${statsTableClasses.rateCell}`]: {
    marginInline: '-10px',
    paddingInline: '10px',
    [`.${statsTrendedValueClasses.root}`]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 16px',
      [`.${statsTrendedValueClasses.primaryValue}`]: {
        textAlign: 'right',
      },
    },
  },

  [`.${statsTableClasses.intervalCell}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 5fr',
    [`.${statsTableClasses.intervalCellPrimary}`]: {
      textAlign: 'right',
    },
    [`.${statsTableClasses.intervalCellSecondary}`]: {
      textAlign: 'left',
    },
  },
  [`.${statsTrendedValueClasses.root}`]: {
    fontSize: '14px',
    justifyContent: 'right',
  },
  [`.${statsTrendedValueClasses.icon}`]: {
    width: 16,
    height: 16,
  },
  [`.${statsTrendedValueClasses.secondaryValue}`]: {
    fontSize: '10px',
  },
}));

export type StatsFieldSimpleValue = number | string;
export type StatsFieldRateValue = object | [number, number];
export type StatsFieldValue = StatsFieldSimpleValue | StatsFieldRateValue;
export type StatsFieldMap = Record<string, StatsFieldValue>;
export type StatsTableRow<
  F extends StatsFieldMap,
  I extends StatsFieldMap = F,
> = {
  [P in keyof F]: F[P];
} & {
  tailNumber: string;
  rate: [number, number];
  intervals: Record<string, StatsTableRowInterval<I>>;
};

type StatsTableRowInterval<I> = {
  [P in keyof I]: I[P];
} & {
  date: string;
};

export interface StatsTableProps<
  F extends StatsFieldMap,
  I extends StatsFieldMap = F,
> {
  rows: StatsTableRow<F, I>[];
  rateField?: {
    valueFormatter: (value: number) => string;
  };
  intervalField: {
    field: keyof I;
    valueFormatter: (value: number) => string;
  };
  intervalSecondaryField?: {
    field: keyof I;
    valueFormatter: (value: number) => string;
  };
  summaryColumns: (GridColDef & {
    field: keyof StatsTableRow<F, I>;
    label: string;
    valueFormatter: (value: number) => string;
  })[];
  intervalColumns: GridColDef[];
  intervalGroupTitle?: string;
  rateStrategy?: 'asc' | 'desc';
}

export const StatsTable = <F extends StatsFieldMap, I extends StatsFieldMap>({
  rateField,
  intervalColumns,
  intervalGroupTitle,
  intervalField: primaryField,
  intervalSecondaryField: secondaryField,
  rows,
  summaryColumns,
  rateStrategy = 'asc',
}: StatsTableProps<F, I>) => {
  const maxRate = useMemo(
    () => Math.max(...rows.map(row => row.rate[0])),
    [rows],
  );
  const minRate = useMemo(
    () => Math.min(...rows.map(row => row.rate[0])),
    [rows],
  );
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'tailNumber',
        headerName: 'Tail Number',
        width: 120,
        align: 'left',
      },
      ...summaryColumns.map(({ field, label, valueFormatter }) => {
        if (field === 'rate') {
          return {
            field: 'rate',
            headerName: label,
            width: 170,
            minWidth: 160,
            sortComparator: (a: [number, number], b: [number, number]) =>
              a[0] - b[0],
            renderCell: ({
              value: [rate, prevRate],
              ...rest
            }: GridRenderCellParams) => {
              const ratePerCent = (rate - minRate) / (maxRate - minRate);
              const rateBgColor =
                (Number.isNaN(ratePerCent) && 'inherit') ||
                `${ratedColor(
                  rateStrategy === 'asc' ? 1 - ratePerCent : ratePerCent,
                )}20`;
              const rateColor =
                (Number.isNaN(ratePerCent) && 'inherit') ||
                `${ratedColor(
                  rateStrategy === 'asc' ? 1 - ratePerCent : ratePerCent,
                )}`;
              let kind: 'positive' | 'negative' | undefined;
              if (rate !== prevRate) {
                if (
                  (rateStrategy === 'asc' && rate > prevRate) ||
                  (rateStrategy === 'desc' && rate < prevRate)
                ) {
                  kind = 'positive';
                } else {
                  kind = 'negative';
                }
              }
              return (
                <Box
                  className={statsTableClasses.rateCell}
                  sx={{
                    backgroundColor: rateBgColor,
                    color: rateColor,
                  }}
                >
                  <TrendedValue
                    icon={
                      (rate === prevRate && 'balance') ||
                      (rate < prevRate && 'down') ||
                      'up'
                    }
                    kind={kind}
                    primaryValue={valueFormatter(rate)}
                    secondaryValue={` / ${valueFormatter(prevRate)}`}
                  />
                </Box>
              );
            },
          };
        }
        return {
          field,
          headerName: label,
          align: 'right',
          minWidth: 130,
          valueFormatter: (value, ...rest) =>
            valueFormatter ? valueFormatter(value, ...rest) : value,
        } as GridColDef;
      }),
      ...intervalColumns.map(
        ({ field }) =>
          ({
            field,
            headerName: dayjs(field).format('MM/DD/YYYY'),
            minWidth: 120,
            type: 'custom',
            align: 'center',
            valueGetter: (_, row) => row.intervals[field],
            sortComparator: (a, b) =>
              a[primaryField.field] - b[primaryField.field],
            renderCell: ({ value }: GridRenderCellParams) => {
              const primaryValue = value[primaryField.field];
              const secondaryValue = value[secondaryField?.field];

              return (
                <Box
                  className={classNames(
                    statsTableClasses.field,
                    statsTableClasses.intervalCell,
                  )}
                >
                  <Box className={statsTableClasses.intervalCellPrimary}>
                    {primaryField.valueFormatter
                      ? primaryField.valueFormatter(primaryValue)
                      : primaryValue}
                  </Box>
                  {secondaryValue !== undefined && (
                    <>
                      <Box className={statsTableClasses.intervalCellDelimiter}>
                        /
                      </Box>
                      <Box className={statsTableClasses.intervalCellSecondary}>
                        {secondaryField?.valueFormatter
                          ? secondaryField.valueFormatter(secondaryValue)
                          : secondaryValue}
                      </Box>
                    </>
                  )}
                </Box>
              );
            },
          } as GridColDef),
      ),
    ],
    [
      intervalColumns,
      maxRate,
      minRate,
      primaryField,
      rateStrategy,
      secondaryField,
      summaryColumns,
    ],
  );
  return (
    <Root
      rows={rows}
      columns={columns}
      getRowClassName={params =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      columnGroupingModel={[
        {
          groupId: 'id',
          headerName: '',
          children: [{ field: 'tailNumber' }],
        },
        {
          groupId: 'rate',
          headerName: 'Current / Previous',
          children: [{ field: 'rate' }],
        },
        {
          groupId: 'intervals',
          headerName: intervalGroupTitle ?? '',
          children: intervalColumns.map(({ field }) => ({ field })),
          headerAlign: 'center',
        },
      ]}
      disableColumnMenu={true}
      pinnedColumns={{ left: ['tailNumber'] }}
      slots={{
        columnSortedAscendingIcon: SortAscIcon,
        columnSortedDescendingIcon: SortDescIcon,
        columnUnsortedIcon: SortIcon,
      }}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'rate',
              sort: rateStrategy,
            },
          ],
        },
      }}
      disableColumnFilter
      disableRowSelectionOnClick
    />
  );
};
