import type { StatsQueryParams } from '../../../adapters/stats/types';

import { useStats } from './useStats';

export const useQueryData = <
  T extends (params: StatsQueryParams) => ReturnType<T>,
>(
  query: T,
) => {
  const { dateRange, selectedAircrafts, activeShortcut } = useStats();

  let dateTo = dateRange[1];
  let dateFrom = dateRange[0];
  if (activeShortcut) {
    dateTo = dateTo.add(1, 'day');
    dateFrom = dateFrom.add(1, 'day');
  } else {
    dateTo = dateTo.add(1, 'day');
  }

  return query({
    dateTo,
    aircrafts: selectedAircrafts,
    fromValue:
      activeShortcut?.periodDefinition[0] ?? dateTo.diff(dateFrom, 'day'),
    fromUnitType: activeShortcut?.periodDefinition[1] ?? 'day',
  });
};
